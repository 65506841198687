// Dependencies
import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
// Hooks
// ...
// Components
import Header from "../components/Header";
import Layout from "../components/Layout";
import FolderWithCards from "../components/FolderWithCards";

const CategoryPage = ({ data }) => {

  return (
    <Layout title={`Paul on ${data.category.title}`} description={data.category.description.text}>
      <Header sticky />
      <FolderWithCards category={data.category} />
    </Layout>
  )
}

CategoryPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default CategoryPage

export const pageQuery = graphql`
  query CategoryQuery($slug: String!) {
    category: contentfulCategory(slug: {eq: $slug}) {
      ...CategoryFragment
    }
  }
`
